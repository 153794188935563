import React, { Fragment } from "react";
import Layout from '../components/layout'
import { Timeline, Event } from "react-timeline-scribble";
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import { experience } from '../experience'
import Helmet from 'react-helmet'
const Experience = () => (
    <StaticQuery
    // tobias-meixner-blue-mountain
    // tyler-lastovich-607521-unsplash.jpg
    query={graphql`
      query ExperienceQuery {
        steps: file(relativePath: { eq: "tyler-lastovich-607521-unsplash.jpg" }) {
          ...fluidImage
        }
      }
    `}
    render={data => (
<Layout>
<Helmet>
      <title>Tobias Meixner - Experience</title>
    </Helmet>
<Img
fluid={data.steps.childImageSharp.fluid}
  style={{
      height: '185px',
      margin: 0,
      objectFit: 'cover'
    }} alt="Steps" />
    <div className={"quote"}>    
    Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work.<br/>
    And the only way to do great work is to love what you do.<br/>
    If you haven’t found it yet, keep looking. Don’t settle. As with all matters of the heart, you’ll know when you find it.

      <span>Steve Jobs</span> 
    </div>
    <h1 style={{
      marginTop: '1rem'
    }}>Experience</h1>
    <div className="timeline"> 
    <Fragment>      
      <Timeline>
        {
          experience.map(e=>{
            return (
              <Event interval={e.interval} title={e.title} subtitle={e.subtitle}>
                {e.detail}
              </Event>
            )
          })          
        }        
      </Timeline>
    </Fragment>
    </div>
  </Layout>
    )} />
)

export default Experience
